import "core-js/modules/es.array.push.js";
import { ElLoading, ElMessage } from 'element-plus';
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import { mixins } from "@/plugins/mixins";
export default {
  name: "feePayParameterAdd",
  mixins: [mixins],
  data() {
    return {
      edit: false,
      iots: [],
      payWays: [{
        value: '微信'
      }, {
        value: '支付宝'
      }],
      payModels: [{
        value: '服务商'
      }, {
        value: '直连'
      }],
      payType: [{
        name: 'H5支付',
        value: 'MWEB'
      }, {
        name: 'App支付',
        value: 'APP'
      }, {
        name: '公众号支付/小程序支付',
        value: 'JSAPI'
      }, {
        name: '慧管宝',
        value: 'MERCHANT_JSAPI'
      }],
      formModel: {
        id: null,
        payWay: null,
        payModel: null,
        appId: null,
        subAppId: null,
        publicKey: null,
        privateKey: null,
        p12: null,
        mchId: null,
        mchKey: null,
        subMchId: null,
        wxParameters: [{
          id: null,
          parkingPayParameterId: null,
          payType: null,
          appId: null
        }]
      },
      formRules: {
        payWay: [{
          required: true,
          message: '请选择支付方式',
          trigger: 'blur'
        }],
        payModel: [{
          required: true,
          message: '请选择支付模式',
          trigger: 'blur'
        }],
        appId: [{
          required: true,
          message: '请输入应用ID',
          trigger: 'blur'
        }, {
          max: 512,
          message: '应用ID长度不能超过512',
          trigger: 'blur'
        }],
        subAppId: [{
          required: true,
          message: '请输入授权码',
          trigger: 'blur'
        }, {
          max: 512,
          message: '授权码长度不能超过512',
          trigger: 'blur'
        }],
        publicKey: [{
          required: true,
          message: '请输入公钥',
          trigger: 'blur'
        }, {
          max: 65535,
          message: '公钥长度不能超过65535',
          trigger: 'blur'
        }],
        privateKey: [{
          required: true,
          message: '请输入私钥',
          trigger: 'blur'
        }, {
          max: 65535,
          message: '私钥长度不能超过65535',
          trigger: 'blur'
        }],
        p12: [{
          required: true,
          message: '请输入微信P12证书',
          trigger: 'blur'
        }, {
          max: 65535,
          message: '微信P12证书长度不能超过65535',
          trigger: 'blur'
        }],
        mchId: [{
          required: true,
          message: '请输入微信商户号',
          trigger: 'blur'
        }, {
          max: 512,
          message: '微信商户号长度不能超过512',
          trigger: 'blur'
        }],
        mchKey: [{
          required: true,
          message: '请输入微信商户密钥',
          trigger: 'blur'
        }, {
          max: 512,
          message: '微信商户密钥长度不能超过512',
          trigger: 'blur'
        }],
        subMchId: [{
          required: true,
          message: '请输入微信子商户号',
          trigger: 'blur'
        }, {
          max: 512,
          message: '微信子商户号长度不能超过512',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    addItem() {
      if (this.formModel.wxParameters === null) {
        this.formModel.wxParameters = [];
      }
      this.formModel.wxParameters.push({
        id: null,
        parkingPayParameterId: null,
        payType: null,
        appId: null
      });
    },
    deleteItem(delItem) {
      this.formModel.wxParameters.forEach(function (item, index, arr) {
        if (item === delItem) {
          arr.splice(index, 1);
        }
      });
    },
    changePayWay() {
      if (this.formModel.payWay === '支付宝') {
        this.formModel.p12 = null;
        this.formModel.mchId = null;
        this.formModel.mchKey = null;
        this.formModel.subMchId = null;
      }
    },
    changePayModel() {
      if (this.formModel.payModel === '直连') {
        this.formModel.subMchId = null;
        this.formModel.subAppId = null;
      }
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.formModel.payWay === '支付宝') {
            this.formModel.wxParameters = null;
          }
          this.$api.business.feePayParameter.save(this.formModel).then(callBack);
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      Promise.all([this.$api.business.feePayParameter.detail({
        id: this.$route.query.id
      })]).then(res => {
        if (res[0].code === 200) {
          this.formModel = Object.assign(this.formModel, res[0].data);
        } else if (res[0].code === 111211) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    } else {
      this.formModel.payWay = '支付宝';
      this.formModel.payModel = '直连';
    }
  }
};